// eslint-disable-next-line no-restricted-imports
import styled from "@emotion/styled";

export const ActionSettingsContent = styled.div`
  margin: 1rem 1.5rem;
`;

export const CopyWidgetContainer = styled.div`
  margin-bottom: 1.25rem;
`;
