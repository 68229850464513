export enum OPERATOR {
  Comma = ",",
  OpenParenthesis = "(",
  CloseParenthesis = ")",
  Plus = "+",
  Minus = "-",
  Star = "*",
  Slash = "/",
  Equal = "=",
  NotEqual = "!=",
  LessThan = "<",
  GreaterThan = ">",
  LessThanEqual = "<=",
  GreaterThanEqual = ">=",
  Not = "not",
  And = "and",
  Or = "or",
  True = "true",
  False = "false",
}
