// eslint-disable-next-line no-restricted-imports
import styled from "@emotion/styled";

export const PanelRoot = styled.div`
  margin: 0 1.5rem;
`;

export const PanelToolbar = styled.div`
  margin-bottom: 1rem;
`;
