// eslint-disable-next-line no-restricted-imports
import styled from "@emotion/styled";

import { Ellipsified } from "metabase/core/components/Ellipsified";

export const StyledEllipsified = styled(Ellipsified)`
  font-weight: bold;
  display: inline-block;
  margin: 0 0.2rem;
`;

export const OptionsMessage = styled.div`
  padding: 2rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
`;
