// eslint-disable-next-line no-restricted-imports
import styled from "@emotion/styled";

import { space } from "metabase/styled-components/theme";

export const ModalHeader = styled.h2`
  padding: ${space(3)};
`;

export const ModalSubtitle = styled.p`
  padding: 0 ${space(3)};
`;

export const ModalRadioRoot = styled.div`
  padding: ${space(0)} ${space(3)};
`;
