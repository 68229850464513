// eslint-disable-next-line no-restricted-imports
import styled from "@emotion/styled";

import { ModalHeader } from "metabase/components/ModalContent";

export const EmbedModalHeader = styled(ModalHeader)`
  padding: 1.5rem 2rem;
  color: var(--mb-color-text-medium);
  border-bottom: 1px solid var(--mb-color-border);
`;
