// This file was generated by lezer-generator. You probably shouldn't edit it.
export const Reference = 1,
  Expression = 2,
  Number = 3,
  String = 4,
  Escape = 5,
  Boolean = 6,
  True = 7,
  Identifier = 8,
  False = 9,
  BinaryExpression = 10,
  And = 15,
  Or = 16,
  ParenExpression = 23,
  CallExpression = 26,
  ArgList = 27,
  Arg = 28,
  UnaryExpression = 30,
  Not = 31,
  SignedExpression = 32;
